<template>
	<div class="container">
		<div class="d-flex justify-content-between align-items-center mb-4">
			<div>
				<h3 class="h3 mb-3">表單範本</h3>
				<nav style="--bs-breadcrumb-divider: '>'">
					<ol class="breadcrumb mb-0">
						<li class="breadcrumb-item">
							<router-link :to="{ name: 'Template' }">表單範本管理</router-link>
						</li>
						<li v-if="routeID" class="breadcrumb-item active">
							{{ detailData.title }}
						</li>
						<li v-else class="breadcrumb-item active">新增表單範本</li>
					</ol>
				</nav>
			</div>
		</div>

		<div class="form-builder-wrap">
			<div class="d-flex mb-4">
				<div style="width: 400px">
					<input type="text" class="form-control me-3" placeholder="表單名稱" v-model="detailData.title" />
				</div>

				<button class="btn btn-primary ms-auto" @click="showPreviewModal()">預覽</button>
			</div>

			<draggable
				:list="detailData.questions"
				item-key="id"
				class="list-group"
				ghost-class="ghost"
				@start="dragging = true"
				@end="dragging = false"
				handle=".handle"
			>
				<template #item="{ element, index }">
					<div class="list-group-item px-3">
						<div class="d-flex align-items-center">
							<a v-if="detailData.canEdit" class="handle me-3" role="button"
								><font-awesome-icon :icon="['fas', 'bars']"
							/></a>
							<div>
								<small class="text-muted mb-2">{{ typeList[element.type] }}</small>
								<h5 class="white-space-pre-wrap mb-0 me-3" v-html="getTitle(element.title)"></h5>
							</div>
							<a class="edit ms-auto me-3" @click="showEditModal(index)" role="button">
								<font-awesome-icon :icon="['fas', 'pen']"
							/></a>
							<a v-if="detailData.canEdit" class="close" @click="removeQuestion(index)" role="button">
								<font-awesome-icon :icon="['fas', 'trash-can']"
							/></a>
						</div>
					</div>
				</template>
			</draggable>

			<div v-if="detailData.canEdit" class="d-grid mt-3">
				<button class="btn btn-success" @click="showEditModal()">
					<font-awesome-icon :icon="['fas', 'add']" class="me-2" />新增問題
				</button>
			</div>
		</div>

		<div class="d-flex justify-content-center align-items-center mt-5">
			<button type="button" class="btn btn-secondary me-3" @click="cancel()">取消</button>
			<button type="button" class="btn btn-primary" @click="submit()">儲存表單範本</button>
		</div>
	</div>

	<div id="editModal" class="modal fade" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ routeID >= 0 ? "編輯" : "新增" }}問題</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<FormBuilder ref="editForm$" />
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
					<button type="button" class="btn btn-primary" @click="editSubmit()">確定</button>
				</div>
			</div>
		</div>
	</div>

	<div id="previewModal" class="modal fade" tabindex="-1">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">預覽</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<FillBuilder ref="prviewForm$" />
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
					<button type="button" class="btn btn-primary" @click="previewSubmit()">檢查</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { textEllipsis, formatLabel, removeHTMLTags } from "@/assets/js/common.js";
import { apiTemplate } from "@/assets/js/api.js";
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "bootstrap";
import FormBuilder from "@/components/FormBuilder";
import FillBuilder from "@/components/FillBuilder";
import draggable from "vuedraggable";

export default {
	name: "TemplateBuild",
	components: {
		FormBuilder,
		FillBuilder,
		draggable,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const routeID = route.query.id;
		const detailData = ref({
			id: 0,
			type: 0,
			canEdit: true,
			title: "",
			questions: [],
		});

		const dragging = ref(false);

		const typeList = {
			title: "標題",
			content: "文字段落",
			textarea: "簡答題",
			textarea_lg: "問答題",
			radiogroup: "單選題",
			checkboxgroup: "多選題",
			select: "下拉選擇題",
			checkbox: "勾選段落",
			text_inline: "文字填充",
			date: "日期選項",
			time: "時間選項",
		};

		let editModal = {};
		const editIndex = ref(-1); // -1: add ; >= 0: edit
		const editForm$ = ref(null);

		let previewModal = {};
		const prviewForm$ = ref(null);

		const customID = computed(() => store.state.template.customID);

		const setPageLoading = (isLoading) => store.dispatch("setPageLoading", isLoading);

		const getTitle = (title) => {
			title = formatLabel(title);
			title = removeHTMLTags(title);
			title = textEllipsis(title, 25);
			return title;
		};

		const getDetail = (id) => {
			return new Promise((resolve, reject) => {
				setPageLoading(true);

				apiTemplate({
					method: "get",
					id,
				})
					.then((response) => {
						setPageLoading(false);
						if (response.data.status == "0") {
							resolve(response.data.data.template);
						} else {
							reject();
						}
					})
					.catch(() => {
						setPageLoading(false);
						reject();
					});
			});
		};

		const showEditModal = (index) => {
			let data = {};
			if (index !== undefined) {
				// edit
				data = detailData.value.questions[index];
				editIndex.value = index;
			} else {
				//add
				editIndex.value = -1;
			}
			editForm$.value.setFormData({
				canEdit: detailData.value.canEdit,
				data,
			});

			editModal.show();
		};

		const editSubmit = () => {
			editForm$.value
				.getResultSchema()
				.then((result) => {
					if (editIndex.value >= 0) {
						// edit
						detailData.value.questions[editIndex.value] = result;
					} else {
						// add
						detailData.value.questions.push(result);
					}

					editModal.hide();
				})
				.catch(() => {});
		};

		const removeQuestion = (index) => {
			Swal.fire({
				icon: "question",
				text: "確定要刪除嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					detailData.value.questions.splice(index, 1);
				}
			});
		};

		const showPreviewModal = () => {
			prviewForm$.value
				.setFormData({
					templateID: detailData.value.id,
					title: detailData.value.title,
					questions: detailData.value.questions,
				})
				.then(() => {
					previewModal.show();
				});
		};

		const previewSubmit = () => {
			prviewForm$.value
				.getResult()
				.then((result) => {
					Swal.fire({
						icon: "success",
						text: "檢查可送出",
					});
				})
				.catch((err) => {});
		};

		const cancel = () => {
			Swal.fire({
				icon: "question",
				text: "內容尚未儲存，確定要取消嗎?",
				showCancelButton: true,
				confirmButtonText: "確定",
				cancelButtonText: "取消",
			}).then((result) => {
				if (result.isConfirmed) {
					router.push({ name: "Template" });
				}
			});
		};

		const submit = () => {
			let invalid = [];

			if (detailData.value.title.length == 0) {
				invalid.push("表單範本名稱 不能留空。");
			} else if (detailData.value.title.length > 40) {
				invalid.push("表單範本名稱 不能多於 40 個字符。");
			} else if (detailData.value.title.length < 2) {
				invalid.push("表單範本名稱 不能少於 2 個字符。");
			}

			if (detailData.value.questions.length == 0) {
				invalid.push("問題 不能留空。");
			}

			if (invalid.length > 0) {
				Swal.fire({
					icon: "warning",
					html: invalid.join("<br>"),
				});
				return;
			}

			const title = detailData.value.title;
			const type = detailData.value.type;
			const content = detailData.value.questions;

			// 健康資訊報告
			if (routeID == 4) {
				for (let i = 0; i < content.length; i++) {
					if (i == 4) content[i].key = "question1";
					else if (i == 7) content[i].key = "question2";
					else if (i == 10) content[i].key = "question3";
					else if (i == 13) content[i].key = "question4";
					else if (i == 22) content[i].key = "question5";
					else if (i == 24) content[i].key = "question6";
					else if (i == 29) content[i].key = "question7";
					else if (i == 32) content[i].key = "question8";
					else if (i == 36) content[i].key = "question10";
					else if (i == 40) content[i].key = "question11";
					else if (i == 52) content[i].key = "question12";
					else if (i == 55) content[i].key = "question13";
					else if (i == 61) content[i].key = "question14";
					else if (i == 63) content[i].key = "question15";
					else content[i].key = "";
				}
			}

			const data = {
				title,
				type,
				content: JSON.stringify(content),
			};

			let apiOptions = {
				method: "",
				data,
			};
			if (routeID) {
				//edit
				apiOptions.method = "put";
				apiOptions.id = routeID;
			} else {
				//add
				apiOptions.method = "post";
			}

			setPageLoading(true);

			apiTemplate(apiOptions)
				.then((response) => {
					setPageLoading(false);
					if (response.data.status == "0") {
						Swal.fire({
							icon: "success",
							text: response.data.message,
						});
						router.push({ name: "Template" });
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}
				})
				.catch(() => {
					setPageLoading(false);
				});
		};

		onMounted(() => {
			editModal = new Modal(document.getElementById("editModal"));
			previewModal = new Modal(document.getElementById("previewModal"));

			// edit
			if (routeID) {
				getDetail(routeID)
					.then((result) => {
						let { id, title, content, type, use_count } = result;
						content = content.length == 0 ? [] : JSON.parse(content);

						detailData.value = {
							id,
							title,
							type,
							questions: content,
							canEdit: use_count == 0 && type == 0 && customID.value.indexOf(id) < 0,
						};
					})
					.catch(() => {
						router.push({ name: "Template" });
					});
			}
		});

		return {
			routeID,
			editForm$,
			prviewForm$,
			dragging,
			detailData,
			typeList,
			getTitle,
			showEditModal,
			editSubmit,
			removeQuestion,
			showPreviewModal,
			previewSubmit,
			cancel,
			submit,
		};
	},
};
</script>
<style lang="scss" scoped>
.form-builder-wrap {
	width: 100%;
	max-width: 700px;
	margin: auto;
}

.list-group-item {
	.close,
	.edit {
		opacity: 0;
		transition: 0.2s;
	}

	&:hover {
		.close,
		.edit {
			opacity: 1;
		}
	}
}
</style>
