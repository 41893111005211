<template>
	<div class="px-3">
		<Vueform ref="formBuild$" :schema="buildSchema" :display-errors="false" validate-on="step"> </Vueform>
	</div>
</template>

<script>
import { ref } from "vue";
export default {
	name: "FormBuilder",
	setup() {
		const typeList = {
			title: "標題",
			content: "文字段落",
			textarea: "簡答題",
			textarea_lg: "問答題",
			radiogroup: "單選題",
			checkboxgroup: "多選題",
			select: "下拉選擇題",
			checkbox: "勾選段落",
			text_inline: "文字填充",
			date: "日期選項",
			time: "時間選項",
		};

		const formBuild$ = ref(null);

		const buildSchema = ref({
			type: {
				label: "類型",
				type: "select",
				native: false,
				search: true,
				disabled: false,
				items: typeList,
				default: "",
				canClear: false,
				canDeselect: false,
				rules: ["required"],
				onChange: (value) => {
					typeOnChange(value);
				},
			},
			title: {
				label: "",
				type: "textarea",
				autogrow: false,
				rules: [
					"required",
					"min:1",
					{ "max:40": ["type", ["title", "text_inline"]] },
					{ "max:200": ["type", ["textarea", "textarea_lg", "radiogroup", "checkboxgroup", "select"]] },
					{ "max:600": ["type", ["checkbox", "content"]] },
				],
				conditions: [
					[
						"type",
						[
							"title",
							"content",
							"text",
							"textarea",
							"textarea_lg",
							"select",
							"radiogroup",
							"checkboxgroup",
							"checkbox",
							"text_inline",
						],
					],
				],
			},
			checkOptions: {
				label: "選項",
				type: "list",
				conditions: [["type", ["radiogroup", "checkboxgroup"]]],
				sort: true,
				disabled: false,
				min: 1,
				max: 20,
				addText: "+ 新增選項",
				controls: {
					sort: true,
				},
				object: {
					addClasses: {
						ElementLayout: {
							outerWrapper: "bg-light p-3 rounded mb-3",
						},
					},
					schema: {
						label: {
							label: "選項文字",
							type: "text",
							rules: ["required", "min:1", "max:60"],
						},
						allowText: {
							text: "開放填答",
							type: "checkbox",
							conditions: [["type", ["radiogroup", "checkboxgroup"]]],
						},
					},
				},
				rules: ["required"],
			},
			selectOptions: {
				label: "選項",
				type: "list",
				conditions: [["type", ["select"]]],
				sort: true,
				disabled: false,
				min: 1,
				max: 20,
				addText: "+ 新增選項",
				controls: {
					sort: true,
				},
				element: {
					label: "選項文字",
					type: "text",
					rules: ["required", "min:1", "max:60"],
				},
				rules: ["required"],
			},
		});

		const typeOnChange = (type) => {
			formBuild$.value.update({
				title: "",
				checkOptions: [],
				selectOptions: [],
			});

			if (type == "checkbox") {
				buildSchema.value.title.label = "問題文字";
			} else if (type == "title") {
				buildSchema.value.title.label = "標題文字";
			} else if (type == "content") {
				buildSchema.value.title.label = "段落文字";
			} else {
				buildSchema.value.title.label = "問題";
			}

			formBuild$.value.resetValidators();
		};

		const getResultSchema = () => {
			return new Promise((resolve, reject) => {
				formBuild$.value.validate().then(() => {
					if (!formBuild$.value.invalid) {
						const data = formBuild$.value.data;

						resolve(data);
					} else {
						reject();
					}
				});
			});
		};

		const resetForm = () => {
			setEditConditions(true);

			formBuild$.value.reset();
			formBuild$.value.resetValidators();
		};

		const setFormData = ({ canEdit = true, data }) => {
			/*
				canEdit: 是否可編輯
				data: 表單資料
			*/

			setEditConditions(canEdit);

			formBuild$.value.load(data);
		};

		const setEditConditions = (canEdit) => {
			/*
				不可編輯時 (canEdit == false)
				問題: 不可新增/刪除/移動，可編輯文字
				選項: 不可新增/刪除/移動，可編輯文字
			*/
			buildSchema.value.type.disabled = !canEdit;
			buildSchema.value.checkOptions.sort = canEdit;
			buildSchema.value.checkOptions.disabled = !canEdit;
			buildSchema.value.selectOptions.sort = canEdit;
			buildSchema.value.selectOptions.disabled = !canEdit;
		};

		return {
			formBuild$,
			buildSchema,
			getResultSchema,
			resetForm,
			setFormData,
			typeOnChange,
		};
	},
};
</script>
